import React from "react";
import { FaHome } from "react-icons/fa"; // Import the Home icon
import { Link } from "react-router-dom"; // Import Link from React Router

const Breadcrumb = ({ items }) => {
  return (
    <nav className="flex mb-4" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-2">
        {items.map((item, index) => (
          <li key={index} className="inline-flex items-center">
            {index > 0 && <span className="mx-2 text-gray-400">/</span>}
            {index === 0 ? (
              <Link
                to={item.href}
                className="text-gray-700 hover:text-blue-600 text-sm font-medium flex items-center"
              >
                <FaHome className="mr-1" />
                {item.name}
              </Link>
            ) : (
              <Link
                to={item.href}
                className="text-gray-700 hover:text-blue-600 text-sm font-medium"
              >
                {item.name}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
